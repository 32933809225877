<template>
  <div class="message-item" :class="{hasRead: item.hasRead}">

<!--    奖励消息-->
    <template v-if="item.type===1">
      <div class="icon">
        <img src="https://cdn.001ppt.cn/h5/assets/images/gold.png" alt="">
      </div>

      <div class="main">
        <div class="title">
          奖励消息
          <div class="time">{{item.createTime}}</div>
        </div>
        <div>{{item.task.taskName}}</div>
      </div>

      <div class="handle task" @click="goTaskRecord">
        看收益
      </div>
    </template>


<!--    收益消息-->
    <template v-else-if="item.type===2">
      <div class="icon">
        <img :src="item.profit.unlockUserAvatar" alt="">
      </div>

      <div class="main" v-if="item.profit.taskId===10 || item.profit.taskId === 11">
        <div class="title">
          <div class="nickName">{{item.profit.unlockUsername}}</div>
          <div class="time">{{item.createTime}}</div>
        </div>
        <div>解锁了我的方案</div>
      </div>

      <div class="handle task" @click="goProfitRecord">
        看收益
      </div>
    </template>


<!--    动态消息-->
    <template v-else-if="item.type===3">
      <div class="icon" @click="goUser(item.dynamic.userId)">
        <img :src="item.dynamic.userAvatar" alt="">
      </div>
      <div class="main">
        <div class="title">
          <div class="nickName" @click="goUser(item.dynamic.userId)">{{item.dynamic.username}}</div>
          <div class="time">{{item.createTime}}</div>
        </div>
        <div class="info">
          <template v-if="item.subType===1">关注了我</template>
          <template v-else-if="item.subType===2">
            收藏了我的方案 <span class="link">{{item.dynamic.planTitle}}</span>
          </template>
          <template v-else-if="item.subType===3">
            推荐了我的方案 <span class="link">{{item.dynamic.planTitle}}</span>
          </template>
        </div>
      </div>
      <div class="handle" @click="handleDynamic">看详情</div>
    </template>


<!--    系统消息-->
    <template v-else-if="item.type===4">
      <div class="icon system">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/icon/yanzhi-normal.png" alt=""
             v-if="item.subType===3">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/icon/warning.png" alt="" v-else>
      </div>
      <div class="main">
        <div class="title">
          <div class="nickName">
            <template v-if="item.subType===1">灵感客服</template>
            <template v-else>审核提醒</template>
          </div>
          <div class="time">{{item.createTime}}</div>
        </div>
        <div class="info dynamic">
          <template v-if="item.subType===3">
            <div v-html="item.system.content"></div>
          </template>
          <template v-else>
            你的方案 《{{item.system.planTitle}}》未审核通过
          </template>
        </div>
      </div>
      <div class="handle">知道了</div>
    </template>

  </div>
</template>

<script>
export default {
  props: ['item'],

  methods:{

    goTaskRecord(){
      this.$router.push('/record?tab=TASK')
    },

    goProfitRecord(){
      this.$router.push('/record?tab=PROFIT')
    },


    goUser(userId){
      return this.$router.push(`/otherPerson/${userId}`)
    },


    handleDynamic(){
      const item = this.item
      if(item.subType === 1){
        return this.goUser(item.dynamic.userId)
      }else{
        return this.$router.push(`/caseDetail/${item.dynamic.planId}`)
      }
    }

  }

}
</script>

<style scoped lang="less">

.message-item {
  display: flex;
  align-items: center;
}

.message-item.hasRead{
  opacity: .7;
}

.message-item .icon{
  flex-shrink: 0;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9e9e9;
  border-radius: 100px;
}

.message-item .icon img{
  height: 56px;
  width: 56px;
  border-radius: 100px;
}

.message-item .icon.system img{
  height: 50px;
  width: 50px;
}

.message-item .main{
  padding: 0 6px;
  flex-grow: 1;
  font-size: 12px;
}

.message-item .main .title {
  display: flex;
  align-items: center;
  font-weight: bolder;
  padding-bottom: 4px;
  font-size: 14px;
}

.message-item .main .title .time{
  color: #999;
  font-weight: normal;
  padding-left: 4px;
  flex-shrink: 0;
}

.message-item .handle{
  flex-shrink: 0;
  width: 56px;
  height: 24px;
  border-radius: 100px;
  color: #fff;
  background: #151D36;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.message-item .handle.task{
  background: #ffcb64;
}

.link{
  color: #0e8ffb;
  display: inline-block;font-size: 12px;
  max-width: calc(100vw - 54px - 56px - 16px - 56px - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
}

.nickName{
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info{
  display: flex;
  align-items: center;
}

.info.dynamic{
  max-width: calc(100vw - 54px - 56px - 16px - 56px);
  overflow: hidden;
}

</style>
