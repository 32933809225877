<template>
  <div class="message-list" @scroll="onScroll" ref="messageList">
    <van-pull-refresh v-model="refreshing" @refresh="loadPageData({isRefresh: true})">
      <van-list v-model="loading" @load="loadPageData({})"
                :finished="pageData.current * pageData.pageSize > pageData.total">
        <message-item v-for="item in pageData.records" :item="item" :key="item.id"/>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {messageV2Api} from "../../../../api/v2/messageV2Api";
import MessageItem from "./messageItem";
import {sleep} from "../../../../config/util";

export default {
  props: ['type', 'scrollKey'],
  components: {MessageItem},
  mounted() {
    this.loadPageData({isRefresh: true})
  },


  data() {
    return {
      refreshing: false,
      loading: false,
      pageData: {
        current: 1,
        pageSize: 20,
        records: [],
        total: 0
      }
    }
  },


  methods: {

    onScroll(e) {
      const state = {}
      state[this.scrollKey] = this.$refs.messageList.scrollTop
      this.$store.commit('scrollPosition/SET_MESSAGE_SCROLL', state)
    },


    reScroll() {
      const scrollTop = this.$store.state.scrollPosition.message[this.scrollKey]
      console.log(scrollTop)
      this.$refs.messageList.scrollTo({top: scrollTop})
    },


    async readAll() {
      for (const r of this.pageData.records) {
        r.hasRead = true
      }
    },

    async loadPageData({current, pageSize, isRefresh}) {
      try {
        if (this.refreshing || this.loading) {
          return
        }
        if (isRefresh) {
          this.refreshing = true
        } else {
          this.loading = true
        }
        current = isRefresh ? 1 : current
        current = current || this.pageData.current + 1
        pageSize = pageSize || this.pageData.pageSize
        const type = this.type
        const res = await messageV2Api.findMyPage({current, pageSize, type})
        if (isRefresh) {
          this.pageData = res
        } else {
          this.pageData.current = current
          this.pageData.pageSize = pageSize
          this.pageData.records = this.pageData.records || []
          this.pageData.total = res.total
          this.pageData.records.push(...res.records)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.refreshing = false
        this.loading = false
      }
    }
  }

}
</script>

<style scoped>
.message-list {
  height: calc(100vh - 40px - 100px);
  overflow-y: auto;
  background: #f4f4f4;
}
</style>
