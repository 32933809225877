import {v2Request} from "./v2Request";


export const messageV2Api = {

  async findMyPage(params) {
    params.size = params.pageSize || params.size
    params.pageSize = params.size
    const res = await v2Request.get(`/search-svr/message/findMyMessagePage`,{params})
    res.pageSize = res.pageSize || res.size
    res.size = res.pageSize
    return res
  },

  readAll() {
    v2Request.get(`/search-svr/message/readAll`)
  }
}
