<template>
  <div class="message">
    <gzh-dialog :type="1" ref="gzhDialog"/>
    <v-header/>
    <van-tabs background="#fff" animated swipeable>
      <van-tab title="全部消息" name="all">
        <div class="tip">
          <div @click="$refs.gzhDialog.show()">想第一时间收到消息？</div>
          <div @click="readAll">全部已读</div>
        </div>
        <message-list ref="all" scroll-key="all"/>
      </van-tab>
      <van-tab title="奖励消息" name="all">
        <div class="tip">
          <div @click="$refs.gzhDialog.show()">想第一时间收到消息？</div>
          <div @click="readAll">全部已读</div>
        </div>
        <message-list :type="1" ref="task" scroll-key="task"/>
      </van-tab>
      <van-tab title="收益消息" name="all">
        <div class="tip">
          <div @click="$refs.gzhDialog.show()">想第一时间收到消息？</div>
          <div @click="readAll">全部已读</div>
        </div>
        <message-list :type="2" ref="profit" scroll-key="profit"/>
      </van-tab>

    </van-tabs>
  </div>
</template>

<script>
import MessageList from "./components/messageList";
import GzhDialog from '@/components/GzhDialog'
import {messageV2Api} from "../../../api/v2/messageV2Api";
import {sleep} from "../../../config/util";

export default {
  components: {MessageList, GzhDialog},

  async activated() {
    if(this.$refs.all) {
      this.$refs.all.reScroll()
      this.$refs.task.reScroll()
      this.$refs.profit.reScroll()
      await sleep(200)
      this.$store.commit('scrollPosition/SET_MESSAGE_SCROLL', {
        all: 0,
        task: 0,
        profit: 0
      })
    }
  },

  methods: {
    async readAll() {
      await messageV2Api.readAll()
      this.$refs.all.readAll()
      this.$refs.task.readAll()
      this.$refs.profit.readAll()
    }
  }
}
</script>

<style scoped>
.message {
  padding-top: 40px;
}

.tip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 27px;
  font-size: 12px;
  color: rgba(21, 29, 54, 0.3);
}
</style>
